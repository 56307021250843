import React from 'react';
import styles from './style.module.scss';
import greeenArrow from '../../../Assets/Images/arrowGreen.svg';
import { calculatePercentage } from '../../../utils/common.utils';
function DashboardTransactions({ clientTransactionData }) {
  const { transactionAssetValue, transactionsCount } = clientTransactionData;
  const {
    totalTrnxCount = 0,
    fiatTrnxCount = 0,
    cryptoTrnxCount = 0,
    currentTrnxCount = 0,
    currentFiatTrnxCount = 0,
    currentCryptoTrnxCount = 0,
    pastTrnxCount = 0,
    pastFiatTrnxCount = 0,
    pastCryptoTrnxCount = 0,
    thirtyDaysTrnxCount = 0,
    sixtyDaysTrnxCount = 0,
    ninetyDaysTrnxCount = 0
  } = transactionsCount || {};
  const monthData = (monthTitle, total, fiat, crypto, percent) => {
    return (
      <div className={styles.currentMonth}>
        <p>
          {monthTitle}
          <span>
            {total}
            <span style={{fontSize:'12px'}} className={styles.increase}>
              {' '}
              {percent}%
              <img
                style={{ marginRight: '2px' }}
                src={greeenArrow}
                alt='icon'
              />
            </span>
          </span>
        </p>
        <div className={styles.fiatCrypto}>
          <p>
            Fiat <span>{calculatePercentage(fiat,total)}%</span>
          </p>
          <p>
            Crypto <span>{calculatePercentage(crypto,total)}%</span>
          </p>
        </div>
      </div>
    );
  };

  return (
    <section className={styles.transactionsSection}>
      <h2>Transactions</h2>
      <div className={styles.transactionCards}>
        <div className={styles.card}>
          <div className={styles.totalHeader}>
            <h3>
              Total <p>{totalTrnxCount}</p>
            </h3>
            <div className={styles.rightContent}>
              <p>
                Fiat <span>{calculatePercentage(fiatTrnxCount,totalTrnxCount)}%</span>
              </p>
              <p>
                Crypto <span>{calculatePercentage(cryptoTrnxCount,totalTrnxCount)}%</span>
              </p>
            </div>
          </div>
          <p className={styles.border}></p>
          <div className={styles.transactionContant}>
            {monthData(
              'Current Month',
              currentTrnxCount,
              currentFiatTrnxCount,
              currentCryptoTrnxCount,
              calculatePercentage(currentTrnxCount,totalTrnxCount)
            )}
            {monthData(
              'Last Month',
              pastTrnxCount,
              pastFiatTrnxCount,
              pastCryptoTrnxCount,
              calculatePercentage(pastTrnxCount,totalTrnxCount)
            )}
          </div>
        </div>

        <div className={styles.card}>
          <h3 style={{fontSize:'18px',fontWeight:'600'}}>AVG #</h3>
          <div className={styles.avg}>
            <p>
              <span>{Number(thirtyDaysTrnxCount).toFixed(0)}</span> Last 30 Days
            </p>
            <p>
              <span>{Number(sixtyDaysTrnxCount).toFixed(0)}</span> Last 60 Days
            </p>
            <p>
              <span>{Number(ninetyDaysTrnxCount).toFixed(0)}</span> Last 90 Days
            </p>
          </div>
        </div>

        <div className={styles.card}>
          <h3 style={{fontSize:'18px',fontWeight:'600'}}>Value $</h3>
          <div className={styles.avg}>
            <p>
              <span>${Number(transactionAssetValue?.thirtyDaysTrnxValue).toFixed(2) || 0}</span> Last 30
              Days
            </p>
            <p>
              <span>${Number(transactionAssetValue?.sixtyDaysTrnxValue).toFixed(2) || 0}</span> Last 60
              Days
            </p>
            <p>
              <span>${Number(transactionAssetValue?.ninetyDaysTrnxValue).toFixed(2) || 0}</span> Last 90
              Days
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DashboardTransactions;
