import React, { useEffect, useState } from "react";
import { Table, Space, Pagination, Switch, Button, Modal, Tooltip } from "antd";
import TableFilterTittle from "../../Components/TableFilterTittle/TableFilterTittle.jsx";
import CustomModal from "../../Components/CustomModal/CustomModal.jsx";
import ModalContentMangement from "./ModalContentMangement.jsx";
import Edit from "../../Assets/Images/greyEdit.svg";
import ButtonCommon from "../../Components/Buttoncommon/ButtonCommon.jsx";
import { useNavigate } from "react-router-dom";
import { FlagOutlined, DeleteOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AdminActions } from "../../redux/features/admin/adminActions";
import {
  startLoading,
  finishLoading,
} from "../../redux/features/loading/loadingSlice";

import { yupResolver } from "@hookform/resolvers/yup";
import { Validations } from "../../validations/index.js";
import {
  checkEmptyString,
  formatDateTime,
  handleHideId,
} from "../../utils/common.utils.js";
import CopyToClipboard from "../../Components/CopyToClipboard/CopyToClipboard.jsx";
import toast from "../../services/toast.service.js";
import TextArea from "antd/es/input/TextArea.js";
import PermissionCheck from "../../Components/PermissionCheck/PermissionCheck.jsx";
import ModalUpdateRole from "./ModalUpdateRole.jsx";
import { DeleteIcon } from "../../Assets/Icons/Icons";

const UserMangement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [actionModalTitle, setActionModalTitle] = useState("");
  const [blockReason, setBlockReason] = useState("");
  const [deleteReason, setDeleteReason] = useState("");
  const [deleteAdminId, setDeleteAdminId] = useState();
  const [blockAdminId, setBlockAdminId] = useState();
  const [blockChecked, setBlockChecked] = useState(false);
  const [val, setVal] = useState(-1);
  const [status, setStatus] = useState("");
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(-1);

  const [admins, setAdmins] = useState({
    list: [],
    totalCount: 0,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const form = useForm({
    mode: "all",
    resolver: yupResolver(Validations.userManagementValidation),
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showUpdateModal = (obj) => {
    console.log({ obj });
    setIsUpdateModalOpen(true);

    form.setValue("firstName", obj.firstName);
    form.setValue("lastName", obj.lastName);
    form.setValue("email", obj.email);
    form.setValue("role", obj?.role?.name);
    setSelectedAdminId(obj.adminId);
  };

  const showDeleteModal = (record) => {
    console.log("my ", record.adminId);
    setDeleteAdminId(record.adminId);
    setIsDeleteModalOpen(true);
  };

  const handleOk = async (values) => {
    try {
      dispatch(startLoading());
      let roleData =
        values.role && values.role.length > 0 ? JSON.parse(values.role) : "";
      let data = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
      };
      data.roleId = roleData.roleId;
      data.title = roleData.name;

      const res = await dispatch(AdminActions.createAdmin(data)).unwrap();
      handleCancel();
      getAdmins({ page: 1, limit: 10 });
      form.reset()
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.reset({
      firstName: "",
      lastName: "",
      role: "",
      roleName: "",
    });
  };

  const handleUpdateCancel = () => {
    setIsUpdateModalOpen(false);
    form.reset({
      firstName: "",
      lastName: "",
      role: "",
      roleName: "",
    });
  };

  const handleUpdate = async (values) => {
    try {
      dispatch(startLoading());
      let roleData =
        values.role && values.role.length > 0 ? JSON.parse(values.role) : "";
      let data = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
      };
      data.role = roleData.roleId;
      data.adminId = selectedAdminId;
      console.log(data);

      const res = await dispatch(AdminActions.updateRole(data)).unwrap();
      handleUpdateCancel();
      getAdmins({ page: 1, limit: 10 });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const getAdmins = async (data) => {
    await dispatch(startLoading());
    try {
      const res = await dispatch(AdminActions.getAdmins(data)).unwrap();
      if (res) {
        if (res.list || res.totalCounts > 0) {
          setAdmins({
            // list: formatAdmins(res.list),
            list:res.list,
            totalCount: res.totalCounts || 0,
          });
        } else {
          setAdmins({
            list: [],
            totalCount: 0,
          });
        }
      } else {
        setAdmins({
          list: [],
          totalCount: 0,
        });
      }
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const formatAdmins = (data) => {
    if (data && data.length > 0) {
      return data.map((obj) => {
        return {
          ...obj,
          is2FaEnable: obj.is2FaEnable === false ? "DISABLED" : "ENABLED",
          createdAt: formatDateTime(obj.createdAt),
          updatedAt: formatDateTime(obj.updatedAt),
          isEmailVerified: obj.isEmailVerified === false ? "false" : "true",
          title: checkEmptyString(obj.title),
          lastLoginTime: formatDateTime(obj.lastLoginTime),
          roleId: checkEmptyString(obj.roleId),
          ip: checkEmptyString(
            obj.sessions && obj.sessions.length > 0 ? obj.sessions[0].ip : null
          ),
        };
      });
    }
    return [];
  };

  const getAdminPermissions = async (obj) => {
    try {
      const data = {
        adminId: obj.adminId,
      };
      dispatch(startLoading());
      const res = await dispatch(
        AdminActions.getSelectedRolePermissions(data)
      ).unwrap();
      if (res && res.list && res.list.length > 0) {
        goToUpdatePermissions(res.list);
      } else {
        toast.error("No permissions found to update");
      }
    } catch (error) {
    } finally {
      dispatch(finishLoading());
    }
  };

  const goToUpdatePermissions = (permissionList) => {
    navigate("/role-management/update-permission", { state: permissionList });
  };

  const columns = [
    {
      title: "Admin Id",
      dataIndex: "adminId",
      key: "adminId",
      render: (_, record) => {
        return (
          <>
            {record?.adminId ? (
              <>
                {handleHideId(record?.adminId)}
                <CopyToClipboard userId={record?.adminId} position="top" />
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, record) => {
        return checkEmptyString(record.role.name);
      },
    },

    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: "Email Verified",
    //   dataIndex: "isEmailVerified",
    //   key: "isEmailVerified",
    // },
    {
      title: "Last Login",
      dataIndex: "lastLoginTime",
      key: "lastLoginTime",
    },
    // {
    //   title: "Created At",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    // },
    // {
    //   title: "Updated At",
    //   dataIndex: "updatedAt",
    //   key: "updatedAt",
    // },

    // {
    //   title: "Role Id",
    //   dataIndex: "roleId",
    //   key: "roleId",
    // },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return (
          <>
            <Tooltip placement={"top"} title={record.statusReason}>
              <span> {record.status}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Actions",
      render: (_, record) => {
        return (
          <PermissionCheck>
            <Space size={30}>
              <img
                src={Edit}
                alt="icon"
                style={{ cursor: "pointer" }}
                onClick={() => showUpdateModal(record)}
              />
              <Switch
                checkedChildren="Inactive"
                unCheckedChildren="Active"
                defaultChecked={record.status === "ACTIVE" ? false : true}
                checked={record.status === "ACTIVE" ? false : true}
                onChange={(checked) => onChange(checked, record)}
              />
              {/* <ButtonCommon
                icon={<DeleteOutlined />}
                height={40}
                type="primary"
                text="Delete User"
                onClick={() => showDeleteModal(record)}
              /> */}

              <ButtonCommon
                icon={<DeleteIcon style={{ color: "red" }} />}
                onClick={() => showDeleteModal(record)}
                type="text"
              />
            </Space>
          </PermissionCheck>
        );
      },
    },
  ];

  const onChange = (checked, record) => {
    setStatus(checked === true ? "ACTIVE" : "INACTIVE");

    const selectedAdminIndex = admins.list.findIndex(
      (rec) => rec.adminId === record.adminId
    );
    setVal(selectedAdminIndex);
    setIsActionModalOpen(true);
    setBlockAdminId(record.adminId);
    setBlockChecked(checked);
    const newAdmins = [...admins.list];
    newAdmins[selectedAdminIndex].status =
      checked === true ? "INACTIVE" : "ACTIVE";

    setAdmins((x) => {
      return {
        list: newAdmins,
        totalCount: +x.totalCount,
      };
    });

    if (record.status === "INACTIVE") setActionModalTitle("Inactive");
    else setActionModalTitle("Active");
  };

  const handleBlockCancel = () => {
    const newAdmins = [...admins.list];
    newAdmins[val].status = status;
    setAdmins((x) => {
      return {
        list: newAdmins,
        totalCount: +x.totalCount,
      };
    });

    setIsActionModalOpen(false);
    setStatus("");
  };

  const handleBlockOk = async () => {
    try {
      if (!blockReason) throw "Please enter reason";

      blockUnblockAdmin(blockAdminId, blockChecked);
      setIsActionModalOpen(false);
      const newAdmins = [...admins.list];
      newAdmins[val].statusReason = blockReason;
      setAdmins((x) => {
        return {
          list: newAdmins,
          totalCount: +x.totalCount,
        };
      });
      setStatus("");
    } catch (error) {
      toast.error(error);
    }
  };

  const blockUnblockAdmin = async (adminId, checked) => {
    try {
      const data = {
        adminId,
        status: checked ? "INACTIVE" : "ACTIVE",
        statusReason: blockReason ? blockReason : undefined,
      };
      await dispatch(startLoading());
      await dispatch(AdminActions.blockUnblockAdmin(data)).unwrap();
      await dispatch(finishLoading());
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setDeleteReason("");
  };

  const handleDeleteOk = async () => {
    try {
      if (!deleteReason) throw "Please enter reason";

      deleteAdmin(deleteAdminId);
      setIsDeleteModalOpen(false);
      setDeleteReason("");
    } catch (error) {
      toast.error(error);
    }
  };

  const deleteAdmin = async (adminId) => {
    try {
      const data = {
        adminId,
        statusReason: deleteReason ? deleteReason : undefined,
      };
      await dispatch(startLoading());
      await dispatch(AdminActions.deleteAdmin(data)).unwrap();
      getAdmins({ page: 1, limit: 10 });
      await dispatch(finishLoading());
    } catch (error) {
    } finally {
      await dispatch(finishLoading());
    }
  };

  const handlePageChange = (page, size) => {
    const data = {
      page: page,
      limit: size,
    };

    setPage(page);
    setPageSize(size);
    getAdmins(data);
  };

  useEffect(() => {
    const data = {
      page: 1,
      limit: 10,
    };
    getAdmins(data);
  }, []);

  return (
    <>
      <div className="table-design">
        <div className="title-filter">
          <h3>User Management</h3>
          <PermissionCheck>
            <ButtonCommon
              icon={<FlagOutlined />}
              height={40}
              type="primary"
              text="Add User"
              onClick={showModal}
            />
          </PermissionCheck>
        </div>
        <div className="custom-table">
          <Table
            dataSource={admins.list}
            columns={columns}
            pagination={false}
            // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
      {admins.totalCount > 10 && (
        <div className="pagination_block">
          <span className="records">Total Records: {admins.totalCount}</span>
          <Pagination
            defaultCurrent={page}
            total={admins.totalCount}
            pageSize={pageSize}
            onChange={handlePageChange}
          />
        </div>
      )}

      {/* Add User Modal */}
      <CustomModal
        text="Add User"
        title="Add User"
        open={isModalOpen}
        footer={[
          <Button type="default" htmlType="button" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            type="primary"
            htmlType="button"
            onClick={form.handleSubmit(handleOk)}
          >
            Ok
          </Button>,
        ]}
      >
        <ModalContentMangement form={form} />
      </CustomModal>

      {/* Update Role Modal */}
      <CustomModal
        text="Update Role"
        title="Update Role"
        open={isUpdateModalOpen}
        footer={[
          <Button type="default" htmlType="button" onClick={handleUpdateCancel}>
            Cancel
          </Button>,
          <Button
            type="primary"
            htmlType="button"
            onClick={form.handleSubmit(handleUpdate)}
          >
            Ok
          </Button>,
        ]}
      >
        <ModalUpdateRole form={form} />
      </CustomModal>

      {/* Block User Modal */}
      {isActionModalOpen && (
        <Modal
          open={isActionModalOpen}
          onOk={handleBlockOk}
          onCancel={handleBlockCancel}
          className="custom-modal verificationmodal"
          width={403}
          centered
          closeIcon={true}
          maskClosable={false}
        >
          {/* <TextArea rows={4} placeholder="Enter Reason" value={rejectionReason} onChange={e=>setrejectionReason(e.target.value)} /> */}
          <p style={{ padding: "48px auto 18px auto", textAlign: "center" }}>
            Are you sure you want to {actionModalTitle}?
          </p>
          <TextArea
            rows={4}
            placeholder="Enter Reason"
            onChange={(e) => setBlockReason(e.target.value)}
          />
        </Modal>
      )}

      {/* Delete User Modal */}
      {isDeleteModalOpen && (
        <Modal
          open={isDeleteModalOpen}
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
          className="custom-modal verificationmodal"
          width={403}
          centered
          closeIcon={true}
          maskClosable={false}
        >
          <p style={{ padding: "48px auto 18px auto", textAlign: "center" }}>
            Are you sure you want to Delete this user?
          </p>
          <TextArea
            rows={4}
            placeholder="Enter Reason"
            onChange={(e) => setDeleteReason(e.target.value)}
          />
        </Modal>
      )}
    </>
  );
};

export default UserMangement;
