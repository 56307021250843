import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import toast from '../../../services/toast.service';
import { useSelector } from 'react-redux';
import ButtonCommon from '../../../Components/Buttoncommon/ButtonCommon';

const TransactionScreeningFilters = ({
  setPage,
  setFilterData,
  pageSize,
  getKytTxn,
  type,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: 'all',
  });

  const location = useLocation();
  const { selectedComplianceTab } = useSelector((state) => state?.compliance);
  const [kytStatus, setKytStatus] = useState([]);
  const options = [
    { value: 'ALL', label: 'ALL' },
    {
      value: 'PENDING',
      label: 'PENDING',
    },
    {
      value: 'COMPLETED',
      label: 'COMPLETED',
    },
    {
      value: 'CANCELLED',
      label: 'CANCELLED',
    },
  ];
  const obj = { ...location.state };
  console.log({ locationState: obj });
  const [trans, setTrans] = useState('all');
  /** setting default filters coing from props */
  const [filter, setFilter] = useState({
    coin: obj.coin,
    offset: 0,
    txnStatus: obj.txnStatus,
    type: type,
    size: pageSize || 10,
  });

  const onSubmit = (values) => {
    let isFiltersValid = validateFilters(values);
    if (!isFiltersValid) return;
    values = convertFilterValues(values);
    if (Object.keys(values).length > 0) {
      setFilterData(values);
      getKytTxn(values);
      setPage(1);
    }
  };

  const convertFilterValues = (data) => {
    let values = { ...data };

    if (values.txnStatus === undefined) values.txnStatus = 'ALL';
    values.offset = 0;
    values.size = pageSize || 10;
    values.coin = '';
    values.type = type;
    return values;
  };

  const checkEmptyFilters = (data) => {
    let filterMessage = '';
    let isFiltersValid = true;
    isFiltersValid = Object.values(data).some((value) => !!value);
    if (!isFiltersValid) {
      if (data && data.length === 0) {
        isFiltersValid = true;
      } else {
        filterMessage = 'Please select filters first';
        toast.error(filterMessage);
      }
    }

    return isFiltersValid;
  };

  const validateFilters = (data) => {
    let isFiltersValid = checkEmptyFilters(data);

    return isFiltersValid;
  };

  const clearFilters = () => {
    let isFiltersValid = checkEmptyFilters(getValues());
    if (isFiltersValid) {
      reset({
        txnStatus: 'ALL',
      });
      let data = {
        // offset: (page - 1) * pageSize,
        offset: 0,
        size: 10,
        txnStatus: 'ALL',
        coinName: '',
        type,
      };
      setPage(1);
      setFilterData(data);
      getKytTxn(data);
    }
  };

  useEffect(() => {
    setValue('txnStatus', filter.txnStatus);
  }, [filter]);

  useEffect(() => {
    setValue('txnStatus', 'ALL');
    setFilterData((data) => {
      return {
        ...data,
        txnStatus: 'ALL',
      };
    });
  }, [selectedComplianceTab]);

  useEffect(() => {
    let getKytStatus;
    if (selectedComplianceTab == 'Crypto') {
      getKytStatus = options?.filter((x) => x.label !== 'CANCELLED');
    } else {
      getKytStatus = options;
    }
    setKytStatus(getKytStatus);
  }, [selectedComplianceTab]);

  return (
    <form
      style={{ display: 'inline-flex', gap: '15px', flexFlow: 'wrap' }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Controller
        name='txnStatus'
        control={control}
        render={({ field }) => (
          <Select
            placeholder='Status'
            defaultValue={'ALL'}
            {...field}
            style={{
              minWidth: '150px',
            }}
            options={kytStatus}
          />
        )}
      />
      <div className='d-flex'>
        <ButtonCommon
          text='Submit'
          type='primary'
          transBtn='transBtn'
          htmlType={'submit'}
        />

        <ButtonCommon
          className='filter-btn'
          reject='reject'
          onClick={clearFilters}
          htmlType='button'
        >
          <CloseOutlined />
        </ButtonCommon>
      </div>
    </form>
  );
};

export default TransactionScreeningFilters;
