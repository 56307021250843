import React from "react";
import { Row, Col, Divider, Typography } from "antd";
import style from "./style.module.scss";
import { Utils } from "../../utils";
import { useSelector } from "react-redux";
import { showName } from "../../services/user.service";
import { KYCService } from "../../services/kyc.service";
import { handleHideId } from "../../utils/common.utils";
import CopyToClipboard from "../../Components/CopyToClipboard/CopyToClipboard";

const CommanInfo = ({ rejectApprove }) => {
  const { Text, Title } = Typography;
  const selectedUserData = useSelector((state) => state.user.data);
  console.log({ selectedUserData });
  const { user, kyc } = selectedUserData;
  const { checkEmptyString, formatDateTime, renderConditionalElement } =
    Utils.common;

  const kycProfile =
    user?.accountType === "BUSINESS"
      ? kyc?.businessProfile
      : kyc?.individualProfile;

  let list = [
    {
      id: 1,
      title: "Account type:",
      detail: checkEmptyString(user?.accountType),
    },
    {
      id: 2,
      title: "Name:",
      detail: showName(user),
    },
    // { id: 3, title: "UID:", detail: "IDF2B90D45D6" },
    {
      id: 3,
      title: "System ID:",
      detail: (
        <>
          {user?.userId ? (
            <>
              {handleHideId(user?.userId)}
              <CopyToClipboard userId={user?.userId} position="top" />
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },

    { id: 4, title: "Address:", detail: checkEmptyString(user?.address) },
    {
      id: 5,
      title: "Phone number:",
      detail: checkEmptyString(user?.phoneNumber),
    },
    { id: 6, title: "Country:", detail: checkEmptyString(user?.nationality) },
    {
      id: 7,
      title: "Date of Birth:",
      detail: checkEmptyString(KYCService.getDobByAccountType(user)),
    },
    {
      id: 8,
      title: "Risely ID:",
      detail: (
        <>
          {user?.uidString ? (
            <>
              {handleHideId(user?.uidString)}
              <CopyToClipboard userId={user?.uidString} position="top" />
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    // { id: 8, title: "Created:", detail: "19 Dec 2023 14:48:18" },
    {
      id: 9,
      title: "Created:",
      detail: formatDateTime(user?.createdAt),
    },
    // { id: 9, title: "Updated:", detail: "19 Dec 2023 14:48:18" },
    {
      id: 10,
      title: "Last Updated:",
      detail: formatDateTime(user?.updatedAt),
    },
    {
      id: 10,
      title: "Last Updated By:",
      detail: Utils.common.formatAdminNameWithRole(
        kycProfile?.updatedBy,
        kycProfile?.updatedByRole
      ),
    },
  ];

  list =
    user && user?.accountType === "BUSINESS"
      ? list.filter((x) => x.id !== 7 && x.id !== 4).sort()
      : list;

  const listkyc = [
    {
      id: 1,
      title: "Email Verification",
      titleSub: `Registered on ${formatDateTime(user?.createdAt)}`,
      // detail: <Text type="secondary">Verified</Text>,
      detail: renderConditionalElement(
        user?.isEmailVerified,
        <Text type="secondary">Verified</Text>,
        <Text type="error">Not Verified</Text>
      ),
    },
    {
      id: 2,
      title: "Phone Verification",
      titleSub: `Verified on ${formatDateTime(user?.createdAt)}`,
      detail: renderConditionalElement(
        !!user?.phoneNumber,
        <Text type="secondary">Verified</Text>,
        <Text type="error">Not Verified</Text>
      ),
    },
    {
      id: 3,
      title: "Document KYC Status",
      titleSub: (
        <>
          {kycProfile?.sumsubKycStatus === "APPROVED" &&
            `Verified on ${formatDateTime(kycProfile?.updatedAt)}`}{" "}
          {kycProfile?.sumsubKycStatus === "PENDING" &&
            `Submitted on ${formatDateTime(kycProfile?.updatedAt)}`}
        </>
      ),

      detail: renderConditionalElement(
        kycProfile?.sumsubKycStatus === "APPROVED",
        <Text type="success">Approved</Text>,
        <Text type="error">Not Approved</Text>
      ),
    },
    // {
    //   id: 3,
    //   title: "Document -1",
    //   titleSub: "Verified on 12-12-2023 12:01:22 UTC",
    //   detail: <Text type="success">Approved</Text>,
    // },
    // {
    //   id: 4,
    //   title: "Document -2",
    //   titleSub: "Verified on 12-12-2023 12:01:22 UTC",
    //   detail: (
    //     <>
    //       {rejectApprove ? (
    //         <>
    //           <Text type="success">Approve</Text>
    //           <Text type="danger">/Reject</Text>
    //         </>
    //       ) : (
    //         <Text type="warning">Under Review</Text>
    //       )}
    //     </>
    //   ),
    // },
    // {
    //   id: 5,
    //   title: "Document -3",
    //   titleSub: "Verified on 12-12-2023 12:01:22 UTC",
    //   detail: <Text type="danger">Not Uploaded </Text>,
    // },
  ];
  return (
    <>
      <Row>
        <Col xs={24} lg={12}>
          <div className={style.detailmodalContent_personalInfo}>
            <Title level={5}>Personal Information</Title>
            <ul style={{ padding: "unset" }}>
              {list.map((point) => (
                <li key={point.id}>
                  <span>{point.title}</span>
                  <span>{point.detail}</span>
                </li>
              ))}
            </ul>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className={style.detailmodalContent_kyc}>
            <Title level={5}>KYC Verification Details</Title>
            <ul style={{ padding: "unset" }}>
              {listkyc.map((point) => (
                <li key={point.id}>
                  <span>
                    <b>{point.title}</b>
                    <br />
                    {point.titleSub}
                  </span>
                  <span>{point.detail}</span>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
      <Divider />
    </>
  );
};

export default CommanInfo;
