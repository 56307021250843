import * as yup from 'yup';
import { CONSTANTS } from '../constants';
import BigNumber from 'bignumber.js';

const { VALIDATION_ERRORS } = CONSTANTS;

const loginValidation = (isModalOpen) =>
  yup.object({
    email: yup
      .string()
      .email(VALIDATION_ERRORS.INVALID_EMAIL)
      .required(VALIDATION_ERRORS.EMAIL_REQUIRED),
    password: yup.string().required(VALIDATION_ERRORS.PASSWORD_REQUIRED),
    otp: isModalOpen
      ? yup
        .string()
        .required(VALIDATION_ERRORS.OTP_REQUIRED)
        .test('len', 'Must not less than 6 digits', (val) => val.length === 6)
      : undefined,
  });

const changePasswordValidation = yup.object().shape({
  old_password: yup.string().required(VALIDATION_ERRORS.OLD_PASSWORD_REQUIRED),

  new_password: yup.string().required(VALIDATION_ERRORS.NEW_PASSWORD_REQUIRED),

  reenter_password: yup
    .string()
    .required(VALIDATION_ERRORS.RE_ENTER_PASSWORD_REQUIRED)
    .oneOf([yup.ref('new_password'), null], VALIDATION_ERRORS.PASSWORDS_MATCH),
});

const settingValidation = (isModalOpen) =>
  yup.object().shape({
    firstName: yup.string().required(VALIDATION_ERRORS.FIRST_NAME_REQUIRED),
    lastName: yup.string().required(VALIDATION_ERRORS.LAST_NAME_REQUIRED),
    twoFAEnabled: yup
      .boolean()
      .oneOf([true, false], VALIDATION_ERRORS.TWOFA_ENABLED),
    otp: isModalOpen
      ? yup
        .string()
        .required(VALIDATION_ERRORS.OTP_REQUIRED)
        .test('len', 'Must not less than 6 digits', (val) => val.length === 6)
      : undefined,
  });

const transactionLimits = yup.object({
  withdrawalFee: yup
    .string()
    .required('External fee is required')

    .matches(/^\d*\.?\d+$/, 'External fee must be a number')

    .test(
      'is-numeric',
      'External fee  must be a numeric value',
      (value) => !value || !isNaN(Number(value))
    ),

  externalTransferFee: yup
    .string()
    .required('External Fee is required')

    .matches(/^\d*\.?\d+$/, 'External fee must be a number')

    .test(
      'is-numeric',
      'External Fee must be a numeric value',
      (value) => !value || !isNaN(Number(value))
    )
    .test({
      name: 'isPercentageValid',
      message: 'Fee must be between 0 and 100',
      test: function (value) {
        console.log("this.parent::", this.parent);
        if (this.parent.withdrawFeeType === 'percentage') {
          return value >= 0 && value <= 100;
        }
        return true;
      },
    }),

  internalTransferFee: yup
    .string()
    .required('Internal Fee  is required')

    .matches(/^\d*\.?\d+$/, 'Internal fee must be a number')

    .test(
      'is-numeric',
      'Internal Fee  must be a numeric value',
      (value) => !value || !isNaN(Number(value))
    )
    .test({
      name: 'isPercentageValid',
      message: 'Fee must be between 0 and 100',
      test: function (value) {
        if (this.parent.withdrawFeeType === 'percentage') {
          return value >= 0 && value <= 100;
        }
        return true;
      },
    }),

  internalMinimumWithdraw: yup
    .string()
    .required('Minimum withdrawal internal amount is required')
    .matches(
      /^\d*\.?\d+$/,
      'Minimum withdrawal internal amoun must be a number'
    )

    .test(
      'is-numeric',
      'Minimum withdrawal internal amount must be a numeric value',
      (value) => !value || !isNaN(Number(value))
    )
    .test(
      'is-non-negative',
      'Minimum withdrawal internal amount cannot be negative',
      (value) => !value || Number(value) >= 0
    )
    // .test(
    //   'min-less-than-max',
    //   'The minimum internal withdrawal amount must be greater than the internal fee.',
    //   function (value) {
    //     const { internalTransferFee } = this.parent;
    //     return (
    //       !value ||
    //       !internalTransferFee ||
    //       Number(value) > Number(internalTransferFee) || this.parent.withdrawFeeType === 'percentage'
    //     );
    //   }
    // )
    .test(
      'min-less-than-max',
      'Minimum withdrawal internal amount should be less than maximum withdrawal internal amount',
      function (value) {
        const { internalMaximumWithdraw, internalTransferFee } = this.parent;
        console.log('value:::', value, {
          internalMaximumWithdraw,
          internalTransferFee,
        });

        return (
          !value ||
          !internalMaximumWithdraw ||
          Number(value) < Number(internalMaximumWithdraw)
        );
      }
    ),

  internalMaximumWithdraw: yup
    .string()
    .required('Maximum withdrawal internal amount is required')

    .matches(
      /^\d*\.?\d+$/,
      'Maximum withdrawal internal amoun must be a number'
    )

    .test(
      'is-numeric',
      'Maximum withraw inrernal amount must be a numeric value',
      (value) => !value || !isNaN(Number(value))
    )
    .test(
      'is-non-negative',
      'Maximum withdrawal internal amount cannot be negative',
      (value) => !value || Number(value) >= 0
    )
    .test(
      'max-greater-than-min',
      'Max Withdrawal Internal must be greater than Min Withdrawal Internat',
      function (value) {
        const { internalMinimumWithdraw } = this.parent;
        return (
          !value ||
          !internalMinimumWithdraw ||
          Number(value) > Number(internalMinimumWithdraw)
        );
      }
    ),

  /// Max Document Limit
  //   documentMaxLimitAmount:yup
  //  .string()
  //   .required("Document maximum limit is required")

  //   .matches(/^\d*\.?\d+$/, "Document maximum limit must be a number"),

  miniExternalWithdraw: yup
    .string()
    .required('Minimum withdrawal external amount is required')

    .matches(
      /^\d*\.?\d+$/,
      'Minimum withdrawal external amoun must be a number'
    )

    .test(
      'is-numeric',
      'Minimum withdrawal external amount must be a numeric value',
      (value) => !value || !isNaN(Number(value))
    )
    .test(
      'is-non-negative',
      'Minimum withdrawal externall amount cannot be negative',
      (value) => !value || Number(value) >= 0
    )
    .test(
      'min-less-than-max',
      'The minimum external withdrawal amount must be greater than the external fee',
      function (value) {
        const { maxiExternalWithdraw, externalTransferFee } = this.parent;
        return (
          !value ||
          !externalTransferFee ||
          Number(value) > Number(externalTransferFee) || this.parent.withdrawFeeType === 'percentage'
        );
      }
    )
    .test(
      'min-less-than-max',
      'Minimum withdrawal external amount should be less than maximum  amount',
      function (value) {
        const { maxiExternalWithdraw } = this.parent;
        return (
          !value ||
          !maxiExternalWithdraw ||
          Number(value) < Number(maxiExternalWithdraw)
        );
      }
    ),

  maxiExternalWithdraw: yup
    .string()
    .required('Maximum withdrawal external amount is required')

    .matches(
      /^\d*\.?\d+$/,
      'Maximum withdrawal external amoun must be a number'
    )

    .test(
      'is-numeric',
      'Maximum withdrawal external amount must be a numeric value',
      (value) => !value || !isNaN(Number(value))
    )
    .test(
      'is-non-negative',
      'Maximum withdrawal externall amount cannot be negative',
      (value) => !value || Number(value) >= 0
    )
    .test(
      'max-greater-than-min',
      'Maximum withdrawal external amount should be greater than minimum withdrawal external amount',
      function (value) {
        const { miniExternalWithdraw } = this.parent;
        return (
          !value ||
          !miniExternalWithdraw ||
          Number(value) > Number(miniExternalWithdraw)
        );
      }
    ),

  documentMaxLimitAmount: yup
    .string()
    .required('Doc verification limit is required')

    .matches(/^\d*\.?\d+$/, 'Doc verification limit must be a number')
    .test(
      'is-numeric',
      'Maximum withdrawal external amount must be a numeric value',
      (value) => !value || !isNaN(Number(value))
    )
    .test(
      'is-non-negative',
      'Maximum withdrawal externall amount cannot be negative',
      (value) => !value || Number(value) >= 0
    )

    .test('is-within-range', function (value) {
      const {
        miniExternalWithdraw,
        internalMinimumWithdraw,
        maxiExternalWithdraw,
        internalMaximumWithdraw,
      } = this.parent;

      const minLimit = Math.max(
        Number(miniExternalWithdraw),
        Number(internalMinimumWithdraw)
      );
      const maxLimit = Math.min(
        Number(maxiExternalWithdraw),
        Number(internalMaximumWithdraw)
      );

      const isValid =
        !value || (Number(value) > minLimit && Number(value) < maxLimit);

      return (
        isValid ||
        this.createError({
          message: `The document verification limit amount must be between ${minLimit} and ${maxLimit}.`,
        })
      );
    }),
});

const fiatTransactionLimits = (selectedCoin) => {
  return yup.object().shape({
    withdrawFeeType: yup.string(),

    inernalWithdrawFee: yup
      .string()
      .required('Internal fee is required')

      .matches(/^\d*\.?\d+$/, 'Internal fee must be a number')
      .test({
        name: 'isPercentageValid',
        message: 'Fee must be between 0 and 100',
        test: function (value) {
          if (this.parent.withdrawFeeType === 'PERCENTAGE') {
            return value >= 0 && value <= 100;
          }
          return true;
        },
      }),

    externalWithdrawFee: yup
      .string()
      .required('External fee is required')

      .matches(/^\d*\.?\d+$/, 'External fee must be a number')
      .test({
        name: 'isPercentageValid',
        message: 'Fee must be between 0 and 100',
        test: function (value) {
          if (this.parent.withdrawFeeType === 'PERCENTAGE') {
            return value >= 0 && value <= 100;
          }
          return true;
        },
      }),

    depositFee: yup
      .string()
      .required('Desposit fee is required')

      .matches(/^\d*\.?\d+$/, 'Desposit fee must be a number')
      .test({
        name: 'isPercentageValid',
        message: 'Fee must be between 0 and 100',
        test: function (value) {
          if (this.parent.withdrawFeeType === 'PERCENTAGE') {
            return value >= 0 && value <= 100;
          }
          return true;
        },
      }),

    // withdrawFee: yup
    //   .number()
    //   .required('Fee is required')
    //   .typeError('Fee must be a number')
    //   .min(0, 'Fee cannot be lower than 0')
    //   .test({
    //     name: 'isPercentageValid',
    //     message: 'Fee must be between 0.1 and 100',
    //     test: function (value) {
    //       if (this.parent.withdrawFeeType === 'PERCENTAGE') {
    //         return value >= 0.1 && value <= 100;
    //       }
    //       return true;
    //     },
    //   })
    //   .test({
    //     name: 'isMaxLimitWorking',
    //     message: 'Fee must be up to 2 decimal places',
    //     test: function (value) {
    //       const realValue = new BigNumber(value);
    //       return realValue.decimalPlaces() <= 2;
    //     },
    //   })
    //   .test({
    //     name: 'isFlatMaxLimitWorking',
    //     message: 'Fee must be between 0.5 and 9999',
    //     test: function (value) {
    //       if (this.parent.withdrawFeeType === 'FLAT') {
    //         return (value >= 0.5 && value <= 9999) || value === 0;
    //       }
    //       return true;
    //     },
    //   }),

    withdrawMinFee: yup

      .string()
      .required('Minimum withdraw amount is required')

      .matches(/^\d*\.?\d+$/, 'Minimum withdraw amoun must be a number')
      .test(
        'is-numeric',
        'Minimum withdraw amount must be a numeric value',
        (value) => !value || !isNaN(Number(value))
      )
      .test(
        'is-non-negative',
        'Minimum withdraw amount cannot be negative',
        (value) => !value || Number(value) >= 0
      )
      // .test({
      //   name: 'isPercentageValid',
      //   message: 'Fee must be between 0 and 100',
      //   test: function (value) {
      //     if (this.parent.withdrawFeeType === 'PERCENTAGE') {
      //       return value >= 0 && value <= 100;
      //     }
      //     return true;
      //   },
      // })
      .test(
        'min-less-than-max',
        'The minimum internal withdrawal amount must be greater than the internal fee.',
        function (value) {
          const { inernalWithdrawFee } = this.parent;
          return (
            !value ||
            !inernalWithdrawFee ||
            Number(value) > Number(inernalWithdrawFee) || this.parent.withdrawFeeType === 'PERCENTAGE'
          );
        }
      )
      .test(
        'min-less-than-max',
        'Minimum  withdraw amount should be less than maximum withdrawal amount',
        function (value) {
          const { withdrawMaxFee } = this.parent;
          return (
            !value || !withdrawMaxFee || Number(value) < Number(withdrawMaxFee)
          );
        }
      ),

    withdrawMaxFee: yup
      .string()
      .required('Maximum withdraw amount is required')

      .matches(/^\d*\.?\d+$/, 'Maximum withdraw amoun must be a number')
      .test(
        'is-numeric',
        'Maximum withdraw amount must be a numeric value',
        (value) => !value || !isNaN(Number(value))
      )
      .test(
        'is-non-negative',
        'Maximum withdraw amount cannot be negative',
        (value) => !value || Number(value) >= 0
      )
      .test(
        'max-greater-than-min',
        'Maximum withdraw amount should be greater than minimum withdrawal amount',
        function (value) {
          const { withdrawMinFee } = this.parent;
          return (
            !value || !withdrawMinFee || Number(value) > Number(withdrawMinFee)
          );
        }
      ),
    // .test(
    //   'min-less-than-max',
    //   'The maximum internal withdrawal amount must be greater than the external fee.',
    //   function (value) {
    //     const { inernalWithdrawFee } = this.parent;
    //     return (
    //       !value ||
    //       !inernalWithdrawFee ||
    //       Number(value) > Number(inernalWithdrawFee)
    //     );
    //   }
    // )
    // .test(
    //   'min-less-than-max',
    //   'Minimum  withdraw amount should be less than maximum withdrawal amount',
    //   function (value) {
    //     const { withdrawMaxFee } = this.parent;
    //     return (
    //       !value || !withdrawMaxFee || Number(value) < Number(withdrawMaxFee)
    //     );
    //   }
    // ),
    // Fiat External withdraw

    externalMinWithdrawFee: yup
      .string()
      .required('Internal minimum withdraw amount is required')

      .matches(
        /^\d*\.?\d+$/,
        'Internal minimum withdraw amoun must be a number'
      )
      .test(
        'is-numeric',
        'Internal minimum withdraw amount must be a numeric value',
        (value) => !value || !isNaN(Number(value))
      )
      .test(
        'is-non-negative',
        'Internal minimum withdraw amount cannot be negative',
        (value) => !value || Number(value) >= 0
      )
      // .test({
      //   name: 'isPercentageValid',
      //   message: 'Fee must be between 0 and 100',
      //   test: function (value) {
      //     if (this.parent.withdrawFeeType === 'PERCENTAGE') {
      //       return value >= 0 && value <= 100;
      //     }
      //     return true;
      //   },
      // })

      .test(
        'min-less-than-max',
        'The maximum external withdrawal amount must be greater than the external fee.',
        function (value) {
          const { externalWithdrawFee } = this.parent;
          return (
            !value ||
            !externalWithdrawFee ||
            Number(value) > Number(externalWithdrawFee) || this.parent.withdrawFeeType === 'PERCENTAGE'
          );
        }
      )
      .test(
        'min-less-than-max',
        'Minimum withdraw amount should be less than maximum withdrawal amount',
        function (value) {
          const { externalMaxWithdrawFee } = this.parent;
          return (
            !value ||
            !externalMaxWithdrawFee ||
            Number(value) < Number(externalMaxWithdrawFee)
          );
        }
      ),

    externalMaxWithdrawFee: yup
      .string()
      .required('External maximum withdraw amount is required')

      .matches(
        /^\d*\.?\d+$/,
        'External maximum withdraw amoun must be a number'
      )
      .test(
        'is-numeric',
        'External maximum withdraw amount must be a numeric value',
        (value) => !value || !isNaN(Number(value))
      )
      .test(
        'is-non-negative',
        'External maximum withdraw amount cannot be negative',
        (value) => !value || Number(value) >= 0
      )
      .test(
        'max-greater-than-min',
        'External maximum withdraw amount should be greater than minimum withdrawal amount',
        function (value) {
          const { externalMinWithdrawFee } = this.parent;
          return (
            !value ||
            !externalMinWithdrawFee ||
            Number(value) > Number(externalMinWithdrawFee)
          );
        }
      ),

    docMaximumWithdraw: yup
      .string()
      .required('Document maximum withdraw is required')
      // .matches(/^\d+$/, "Document maximum limit must be a number"), if you dont want to consider to numbers  in poits
      .matches(/^\d*\.?\d+$/, 'Document maximum limit must be a number')
      .test('is-within-range', function (value) {
        const {
          externalMinWithdrawFee,
          withdrawMinFee,
          externalMaxWithdrawFee,
          withdrawMaxFee,
        } = this.parent;

        const minLimit = Math.max(
          Number(externalMinWithdrawFee),
          Number(withdrawMinFee)
        );
        const maxLimit = Math.min(
          Number(externalMaxWithdrawFee),
          Number(withdrawMaxFee)
        );

        const isValid =
          !value || (Number(value) > minLimit && Number(value) < maxLimit);

        return (
          isValid ||
          this.createError({
            message: `The document verification limit amount must be between ${minLimit} and ${maxLimit}.`,
          })
        );
      }),

    //  Inter fee and exter fee
  });
};

const fiatTransactionLimitValidation = yup.object({
  globalLimit: yup
    .string()
    .required('Transaction individual limit amount is required')
    .matches(/^\d*\.?\d+$/, 'Transaction individual limit amoun must be a number'),

  dailyBusinessTrxLimit: yup
    .string()
    .required('Transaction business limit amount is required')
    .matches(/^\d*\.?\d+$/, 'Transaction individual limit amoun must be a number'),
});
const cryptoTransactionLimitValidation = yup.object({
  cryptoTransactionLimit: yup
    .string()
    .required('Transaction individual limit amount is required')
    .matches(/^\d*\.?\d+$/, 'Transaction individual limit amoun must be a number'),

  businessDailyLimit: yup
    .string()
    .required('Transaction business limit amount is required')
    .matches(/^\d*\.?\d+$/, 'Transaction business limit amoun must be a number'),
});

const swapFeeValidation = yup.object({
  platformFee: yup
    .number()
    .typeError('Platform Fee must be a number')
    .required('Platform Fee amount is required')
    .min(0, 'Platform Fee cannot be lower than 0')
    .max(100, 'Platform Fee cannot be greater than 100')
    .test({
      name: 'isNotExponential',
      message: "Platform Fee percentage can't go exponential",
      test: function (value) {
        let stringValue = value.toString();
        console.log({ strlength: stringValue.length, value: stringValue });
        return stringValue.indexOf('e') == -1;
      },
    }),

  minAmount: yup
    .string()
    .required('Minimum amount is required')

    .matches(/^\d*\.?\d+$/, 'Minimum amoun must be a number')
    .test(
      'is-numeric',
      'Minimum amount must be a numeric value',
      (value) => !value || !isNaN(Number(value))
    )
    .test(
      'min-less-than-max',
      'Minimum amount should be less than maximum amount',
      function (value) {
        const { maxAmount } = this.parent;
        return !value || !maxAmount || Number(value) < Number(maxAmount);
      }
    )
    .test(
      'is-non-negative',
      'Minimum amount cannot be negative',
      (value) => !value || Number(value) >= 0
    ),

  maxAmount: yup
    .string()
    .required('Maximum amount is required')

    .matches(/^\d*\.?\d+$/, 'Maximum amoun must be a number')
    .test(
      'is-numeric',
      'Maximum amount must be a numeric value',
      (value) => !value || !isNaN(Number(value))
    )
    .test(
      'max-greater-than-min',
      'Maximum amount should be greater than minimum amount',
      function (value) {
        const { minAmount } = this.parent;
        return !value || !minAmount || Number(value) > Number(minAmount);
      }
    )
    .test(
      'is-non-negative',
      'Maximum amount cannot be negative',
      (value) => !value || Number(value) >= 0
    ),
});

const fiatApprovedTxnValidation = (amount) => {
  return yup.object({
    fiatAmount: yup
      .number()
      .test('is-exact-amount', `Amount must be exactly ${amount}`, (value) => {
        console.log(`CONDITION::`, Number(value) === Number(amount));
        return Number(value) === Number(amount);
      })
      .typeError('Amount must be a number')
      .required('Amount is required.'),
  });
};

const renameAdditionalDoc = yup.object({
  additionalDoc: yup
    .string()
    .required('This field is required')
    .matches(/^\S.*$/, 'This field cannot start with a space')
})



// const userManagementValidation = yup.object({
//   firstName: yup.string().required(VALIDATION_ERRORS.FIRST_NAME_REQUIRED),
//   lastName: yup.string().required(VALIDATION_ERRORS.LAST_NAME_REQUIRED),
//   email: yup
//     .string()
//     .email(VALIDATION_ERRORS.INVALID_EMAIL)
//     .required(VALIDATION_ERRORS.EMAIL_REQUIRED),
//   role: yup.string().required("Role is required"),
//   roleName: yup.string().test({
//     name: "isOtherRoleEntered",
//     message: "Please enter role name",
//     test: function (value) {
//       if (this.parent.role === "OTHER") {
//         return value ? true : false;
//       } else {
//         return true;
//       }
//     },
//   }),
// });
const userManagementValidation = yup.object({
  firstName: yup
    .string()
    .required(VALIDATION_ERRORS.FIRST_NAME_REQUIRED)
    .matches(/^[A-Za-z]+$/, 'First name should be alphabetic only')
    .min(3, VALIDATION_ERRORS.MINIMUM_LENGTH_FOR_FIRST_NAME),
  lastName: yup
    .string()
    .required(VALIDATION_ERRORS.LAST_NAME_REQUIRED)
    .matches(/^[A-Za-z]+$/, 'Last name should be alphabetic only')
    .min(3, VALIDATION_ERRORS.MINIMUM_LENGTH_FOR_LAST_NAME),

  email: yup
    .string()
    .email(VALIDATION_ERRORS.INVALID_EMAIL)
    .required(VALIDATION_ERRORS.EMAIL_REQUIRED),

  role: yup.string().required('Role is required'),
  roleName: yup.string().test({
    name: 'isOtherRoleEntered',
    message: 'Please enter role name',
    test: function (value) {
      if (this.parent.role === 'OTHER') {
        return value ? true : false;
      } else {
        return true;
      }
    },
  }),
});

const nonReferenceValidation = yup.object().shape({
  transactionReference: yup
    .string()
    // .matches(/^\D*$/, 'Transaction reference id must be a string')
    .required(VALIDATION_ERRORS.TRANSACTION_REFERENCE_REQUIRED),

  amount: yup
    .number()
    .typeError('Amount must be a number')
    .required('Amount is required'),
});
const uploadKycDocSchema = yup.object().shape({
  docTitle: yup
    .string()
    .max(50, 'Document title must be at most 50 characters')
    .matches(
      /^[A-Za-z0-9 @#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/, // Allows letters, numbers, and spaces
      'Document title must contain only alphanumeric characters and spaces, no special characters allowed'
    )
    .test(
      'not-only-numbers',
      'Document title cannot be only numbers',
      (value) => !/^[0-9 ]+$/.test(value) // Disallows pure numbers
    )
    .test(
      'not-only-numbers',
      'Document title cannot be only special char',
      (value) => !/^[@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/.test(value) // Disallows pure numbers
    )
    .required('Document title is required'),
});




const kmsSchema = yup.object().shape({
  kmsText: yup.string().required('Description is required'),
});

const transactionRefSchema = yup.object().shape({
  transactionRef: yup.string().required('Transaction Ref is required'),
  amount: yup
    .number()
    .typeError('Amount must be a number')
    .required('Amount is required.'),
});

const referralProgramSchema = yup.object().shape({
  pointType: yup.string().required('Point type is required'),
  minDeposit: yup
  .number()
  .typeError('Minimum deposit must be a number')
  .test(
    'minDepositRequired',
    'Minimum deposit is required',
    function (value) {
      const { pointType } = this.parent; // Access the value of pointType
      if (pointType === 'deposit' || pointType === 'DEPOSIT') {
        return value !== undefined && value !== null; // Ensure minDeposit has a value
      }
      return true; // Otherwise, pass validation
    }
  )
  .test(
    'is-non-negative',
    'Minimum deposit cannot be negative',
    (value) => !value || Number(value) >= 0
  )
  .test(
    'is-integer',
    'Minimum deposit must be a number',
    (value) => !value || Number.isInteger(value) // Prevent float values
  ),
  points: yup
    .number()
    .typeError('Rewards point must be a number')
    .required('Rewards point is required.')
    .test(
      'is-integer',
      'Rewards point must be a number',
      (value) => Number.isInteger(value) // Ensure it's an integer
    )
    .max(10000, 'Rewards point cannot exceed 10000') // Max limit
    .test(
      'is-non-negative',
      'Rewards point cannot be negative',
      (value) => !value || Number(value) >= 0 // Ensure non-negative
    ),
});


export const Validations = {
  login: loginValidation,
  changePassword: changePasswordValidation,
  settings: settingValidation,
  transactionLimits,
  swapFeeValidation,
  fiatApproveTxnValidation: fiatApprovedTxnValidation,
  renameAdditionalDoc,
  userManagementValidation,
  feeSetting: fiatTransactionLimits,
  uploadKycDocSchema,
  kmsSchema,
  transactionRefSchema,
  nonReferenceValidation,
  fiatTransactionLimitValidation,
  cryptoTransactionLimitValidation,
  referralProgramSchema
};
