import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../constants";
import { Utils } from "../../../utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import EncryptionService from "../../../services/encryption.service";

const { WALLET } = CONSTANTS.API_ENDPOINTS;


const updateCryptoTxnStatus = createAsyncThunk(
  "wallet/transaction/approve-reject-transaction",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.UPDATE_TRANSACTION_STATUS}`,
        data: data,
        method: "POST",
        assignedHeaders: { withToken: true, isMultipart: false },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);


const getTransactions = createAsyncThunk(
  "wallet/getTransactions",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.GET_TRANSACTIONS}`,
        data: data,
        method: "POST",
        assignedHeaders: { withToken: true, isMultipart: false },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getBalance = createAsyncThunk(
  "wallet/getBalance",
  async (data, { rejectWithValue, dispatch }) => {
    const { clientId } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.GET_BALANCE}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CLIENT_DIRECTORY",
        },
        params: { clientId },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getTransactionsByClientId = createAsyncThunk(
  "wallet/getTransactionsByClientId",
  async (data, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.GET_TRANSACTIONS_BY_CLIENT_ID}`,
        data: data,
        method: "POST",
        assignedHeaders: { withToken: true, isMultipart: false },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getCryptoTransactions = createAsyncThunk(
  "wallet/getCryptoTransactions",
  async (data, { rejectWithValue, dispatch }) => {
    console.log({ dataHere: data });
    const { clientId, coin, page, size, txStatus, type, fromDate, toDate } =
      data;

    const res = Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.GET_ALL_CRYPTO_TRANSACTIONS}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CRYPTO_TRANSACTIONS",
        },
        params: {
          clientId,
          coin,
          page,
          size,
          txStatus,
          type,
          fromDate,
          toDate,
          trxnType: "CRYPTO",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getLiquidityAndAssets = createAsyncThunk(
  "wallet/getLiquidityAndAssets",
  async (data = {}, { rejectWithValue, dispatch }) => {
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.GET_LIQUIDITY_ASSETS}`,
        data,
        method: "GET",
        assignedHeaders: { withToken: true, isMultipart: false },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getTransactionsDetailsById = createAsyncThunk(
  "wallet/transactions/deatils/byId",
  async (data, { rejectWithValue, dispatch }) => {
    const { coin, clientId, type, apiKey, typeId, from, to } = data;
    const res = await Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.GET_TRANSACTIONS_DETAILS_BY_ID}`,
        data,
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "CRYPTO_TRANSACTIONS",
        },
        params: { coin, clientId, type, apiKey, typeId, from, to },
      },
      { rejectWithValue, dispatch }
    );
    if (res) {
      console.log(res, "CRYPTO TRANSACTION DETAIL RESPONSE ");
      return res;
    }
  }
);

const getSwapTransactionsOnly = createAsyncThunk(
  "wallet/getSwapTransactionsOnly",
  async (data = {}, { rejectWithValue, dispatch }) => {
    // const { offset, limit, userId } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.GET_SWAP_TRANSACTIONS_ONLY}`,
        data,
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "SWAP_TRANSACTIONS",
        },
        params: data,
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      console.log(res, "apiResponse");
      return res;
    }
  }
);

const getLiquidityAndAssetsRtk = createApi({
  reducerPath: "wallet",
  baseQuery: fetchBaseQuery({
    baseUrl: CONSTANTS.ENV.API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.jwtToken;
      if (token) {
        let apiAcccessKey = EncryptionService.encrypt("DASHBOARD");
        headers.set("Api-Access-Token", token);
        headers.set("api-access-key", apiAcccessKey);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLiquidityAndAssets: builder.query({
      query: () => WALLET.GET_LIQUIDITY_ASSETS,
      keepUnusedDataFor: 60,
    }),
  }),
});

const getAllTransactions = createAsyncThunk(
  "wallet/getAllTransactions",
  async (data, { rejectWithValue, dispatch }) => {
    console.log({ dataHere: data });
    const {
      clientId,
      coin,
      page,
      size,
      txStatus,
      type,
      fromDate,
      toDate,
      trxnType,
    } = data;

    const res = Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.GET_ALL_TRANSACTIONS}/${page}/${size}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "ALL_TRANSACTIONS",
        },
        params: {
          clientId,
          coin,
          txStatus,
          type,
          fromDate: !!fromDate ? fromDate : undefined,
          toDate: !!toDate ? toDate : undefined,
          trxnType,
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getSwapTransactionDetails = createAsyncThunk(
  "wallet/getSwapTransactionDetails",
  async (data, { rejectWithValue, dispatch }) => {
    const { typeId, apiAccessKey } = data;

    const res = Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.GET_SWAP_TRANSACTION_DETAIL}/${typeId}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: apiAccessKey || "ALL_TRANSACTIONS",
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getClientsAndTransactions = createAsyncThunk(
  "wallet/getClientsAndTransactions",
  async (data, { rejectWithValue, dispatch }) => {
    // const { typeId, apiAccessKey } = data;

    const res = Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.GET_CLIENTS_AND_TRANSACTIONS}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: 'DASHBOARD',
        },
        params: {},
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getAllAccounts = createAsyncThunk(
  "wallet/getAllAccounts",
  async (data, { rejectWithValue, dispatch }) => {
    const { accountNumber, fromDate, toDate, userId, limit, offset } =
      data || {};
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.GET_ALL_ACCOUNTS}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "DASHBOARD",
        },
        params: { accountNumber, fromDate, toDate, userId, limit, offset },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getFiatTransactions = createAsyncThunk(
  "wallet/getFiatTransactions",
  async (data, { rejectWithValue, dispatch }) => {
    console.log({ dataHere: data });
    const {
      clientId,
      coin,
      page,
      size,
      txStatus,
      type,
      fromDate,
      toDate,
      trxnType,
    } = data;

    const res = Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.GET_ALL_TRANSACTIONS}/${page}/${size}`,
        data: {},
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "FIAT_TRANSACTIONS",
        },
        params: {
          clientId,
          coin,
          txStatus,
          type,
          fromDate: !!fromDate ? fromDate : undefined,
          toDate: !!toDate ? toDate : undefined,
          trxnType: "FIAT",
        },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      return res;
    }
  }
);

const getAllTransactionDetails = createAsyncThunk(
  "wallet/transactions/details/byId",
  async (data, { rejectWithValue, dispatch }) => {
    const { coin, clientId, type, apiKey, typeId, from, to } = data;
    const res = Utils.api.baseApiCall(
      {
        endpoint: `${WALLET.GET_TRANSACTIONS_DETAILS_BY_ID}`,
        data,
        method: "GET",
        assignedHeaders: {
          withToken: true,
          isMultipart: false,
          apiAccessKey: "ALL_TRANSACTIONS",
        },
        params: { coin, clientId, type, apiKey, typeId, from, to },
      },
      { rejectWithValue, dispatch }
    );

    if (res) {
      console.log(res, "apiResponse");
      return res;
    }
  }
);

export const WalletActions = {
  getTransactions,
  getBalance,
  getTransactionsByClientId,
  getCryptoTransactions,
  getLiquidityAndAssets,
  getTransactionsDetailsById,
  getSwapTransactionsOnly,
  getLiquidityAndAssetsRtk,
  getAllTransactions,
  getSwapTransactionDetails,
  getAllAccounts,
  getFiatTransactions,
  getAllTransactionDetails,
  updateCryptoTxnStatus,
  getClientsAndTransactions
};
