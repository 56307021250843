import React, { useState } from "react";
import style from "./style.module.scss";
import ScoreRange from "./ScoreRange/ScoreRange";
import { Button, Table } from "antd";
import RiskManagementEditModal from "./Modals/Edit";
import RiskManagementHistoryModal from "./Modals/History";
import { getColorRiskManagement } from "../../utils/common.utils";
import RiskManagementTableActions from "./TableActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { riskManagementActions } from "../../redux/features/riskManagement/riskManagementActions";
import { EditOutlined } from "@ant-design/icons";
import PermissionCheck from '../../Components/PermissionCheck/PermissionCheck';
const RiskManagement = ({ dataUser }) => {
  const [isModalEditOpen, setIsModalEdit] = useState(false);
  const [isModalHistoryOpen, setIsModalHistory] = useState(false);
  const [sumManual, setSumManual] = useState(0);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [history, setHistory] = useState(null);

  const dataRisk = useSelector((state) => state.riskManagement);

  const dispatch = useDispatch();

  useEffect(() => {
    if (dataUser?.user?.adminKycStatus === 'APPROVED') {
      dispatch(
        riskManagementActions.getQuestionnaire({ userId: dataUser?.user?.userId })
      );
    }
  }, []);

  useEffect(() => {
    if (dataRisk) {
      const calculateSum = () => {
        let sumManual = 0;

        dataRisk?.data?.forEach((item) => {
          const manualScore = parseInt(item?.manualScore, 10);
          if (manualScore === 0) {
            sumManual += parseInt(item?.answer?.score, 10);
          } else {
            sumManual += manualScore;
          }
        });

        return sumManual;
      };

      const sum = calculateSum();
      setSumManual(sum);
    }
  }, [dataRisk]);

  const columns = [
    {
      title: "Item",
      dataIndex: ["question", "title"],
      key: "questionId",
    },
    {
      title: "Answer",
      dataIndex: ["answer", "title"],
      key: "answer",
    },
    {
      title: "System score",
      dataIndex: ["answer", "score"],
      key: "score",
      render: (item) => (
        <div
          style={{
            background: getColorRiskManagement(parseInt(item || 0)),
          }}
          className={style.risk_table_score}
        >
          {item || 0}
        </div>
      ),
    },
    {
      title: "Manual score",
      dataIndex: "manualScore",
      key: "manualScore",
      render: (item) => (
        <div
          style={{ background: getColorRiskManagement(parseInt(item)) }}
          className={style.risk_table_score}
        >
          {item}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, item) => (
      <PermissionCheck>
          <div className={style.risk_table_actions}>
          <Button
            size="large"
            type="primary"
            icon={<EditOutlined />}
            className={style.risk_btn}
            onClick={() => {
              setQuestionnaire(item);
              setIsModalEdit(true);
            }}
          >
            Edit
          </Button>
          <Button
            disabled={!item?.comments?.length}
            size="large"
            type="primary"
            className={style.risk_btn}
            onClick={() => {
              setHistory(item);
              setIsModalHistory(true);
            }}
          >
            History{" "}
            <span className={style.risk_table_actions_historyScore}>
              {item?.comments?.length || 0}
            </span>
          </Button>
        </div>
      </PermissionCheck>
      ),
    },
  ];

  return (
    <>
      {dataUser?.user?.adminKycStatus === 'APPROVED' ? (
        <>
          <div className={style.risk}>
            <div className={style.risk_header}>
              <p className={style.risk_header_title}>Current score</p>
              <ScoreRange currentValue={sumManual || 0}/>
            </div>
            <div className={style.risk_table}>
              <Table
                columns={columns}
                dataSource={dataRisk.data}
                pagination={false}
              />
            </div>
          </div>
          <RiskManagementEditModal
            key="1"
            isModalOpen={isModalEditOpen}
            setIsModalOpen={setIsModalEdit}
            handleCancel={() => setIsModalEdit(false)}
            questionnaire={questionnaire}
          />
          <RiskManagementHistoryModal
            key="2"
            isModalOpen={isModalHistoryOpen}
            setIsModalOpen={setIsModalHistory}
            handleCancel={() => setIsModalHistory(false)}
            history={history}
          />
        </>
      ) : (
        <div className={style.risk_empty}>
          <p className={style.risk_empty_text}>This module will be available when the user passes KYC verification!</p>
        </div>
      )}

    </>
  );
};

export default RiskManagement;
