import React, { useEffect, useState } from 'react';
import { Table, Pagination, Modal, Button, Space } from 'antd';
import ButtonCommon from '../../../../Components/Buttoncommon/ButtonCommon';
import TableFilterTittle from '../../../../Components/TableFilterTittle/TableFilterTittle';
import { useDispatch, useSelector } from 'react-redux';
import { ComplianceActions } from '../../../../redux/features/compliance/complianceAtion';
import {
  finishLoading,
  startLoading,
} from '../../../../redux/features/loading/loadingSlice';
import {
  checkEmptyString,
  formatDateTime,
  handleHideId,
  iconColor,
  iconSize,
} from '../../../../utils/common.utils';
import PermissionCheck from '../../../../Components/PermissionCheck/PermissionCheck';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import TransactionScreeningFilters from '../TransactionScreeningFilters';
import { CloseOutlined, CheckOutlined, EyeOutlined } from '@ant-design/icons';
import { BankingActions } from '../../../../redux/features/banking/bankingActions';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import ComplianceFilterTittle from '../../../../Components/TableFilterTittle/ComplianceTableFilterTitle';
import CopyToClipboard from '../../../../Components/CopyToClipboard/CopyToClipboard';
import { KycActions } from '../../../../redux/features/kyc/kycActions';
import { setTransactionDetail } from '../../../../redux/features/compliance/complianceSlice';

const FiatScreening = ({ getKytTxn, data, kytDetails }) => {
  const { selectedComplianceTab } = useSelector((state) => state?.compliance);
  console.log(selectedComplianceTab, 'pendingCase');
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateStatus, setUpdateStatus] = useState({
    typeId: '',
    status: '',
    amount: '',
    coin: '',
    clientId: '',
    type: 'suspicious-trx',
    withdrawId: '',
  });
  const [filterData, setFilterData] = useState({});
  const [showCancelFiat, setShowCancelFiat] = useState(false);
  const [clickedStatus, setClickedStatus] = useState('');
  const [fiatTxnRecord, setFiatTxnRecord] = useState(null);

  const handlePageChange = async (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    try {
      const data = {
        offset: (page - 1) * pageSize,
        size: pageSize,
        txnStatus: filterData.txnStatus,
        coinName: '',
        type: 'FIAT',
      };
      getKytTxn(data);
    } catch (error) {
      console.log(error);
    }
  };

  const showModal = (record, status) => {
    setClickedStatus(status);
    setShowCancelFiat(true);
    setFiatTxnRecord(record);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    let requestPayload = {};
    try {
      requestPayload = {
        coin: updateStatus.coin.toLowerCase(),
        status: 'COMPLETED',
        typeId: updateStatus.typeId,
        amount: updateStatus.amount,
        type: 'suspicious-trx',
        trxType: updateStatus.txnType,
        clientId: updateStatus.clientId,
      };

      await dispatch(startLoading());
      const res = await dispatch(
        ComplianceActions.updateToKytStatus(requestPayload)
      ).unwrap();

      await getKytTxn();
      console.log(res, 'resresres');
    } catch (error) {
    } finally {
      setIsModalOpen(false);
      await dispatch(finishLoading());
    }
  };
  const handleViewTransactionsDetails = async (record) => {
    if (!record.sumsubTxnFullPayload) {
      return toast.error('No data found');
    } else if (
      record.sumsubTxnFullPayload === '[object Object]' ||
      record.sumsubTxnFullPayload === '{}'
    ) {
      return toast.error('No data found');
    }
    let res = await dispatch(
      BankingActions.getFiatTransactionDetails({
        transactionId: record.txnId,
        apiAccessKey: 'TRANSACTIONS_SCREENING',
      })
    ).unwrap();

    let newRes = await dispatch(
      KycActions.getKycTxnDetails({
        txnId: record?.txnId,
      })
    ).unwrap();
    let data = res.data;
    if (data?.status === 'CANCELLED') {
      dispatch(setTransactionDetail(res.data));
    } else {
      dispatch(
        setTransactionDetail({ ...record, type: record?.type?.toUpperCase() })
      );
    }

    navigate('/transactions-main-detail', {
      state: {
        sumsubTxnFullPayload: newRes,
        status: record.txnStatus,
        data: res.data,
        type: record.type,
        userId: record.userId,
        txnId: record.txnId,
      },
    });
  };

  const showActionsPerType = (record) => {
    let type = record?.type;
    let status = record?.txnStatus;
    console.log({ status });

    if (type) {
      if (type.toUpperCase() === 'FIAT' && status === 'REJECTED') {
        return (
          <Space>
            <PermissionCheck>
              <ButtonCommon
                className='filter-btn'
                reject='approve'
                width={45}
                onClick={() => showModal(record, 'approve')}
              >
                <CheckOutlined />
              </ButtonCommon>

              <ButtonCommon
                className='filter-btn'
                reject='reject'
                onClick={() => showModal(record, 'reject')}
              >
                <CloseOutlined />
              </ButtonCommon>

              <EyeOutlined
                style={{ color: iconColor, fontSize: '20px' }}
                onClick={() => {
                  handleViewTransactionsDetails(record);
                }}
              />
            </PermissionCheck>
          </Space>
        );
      } else if (type.toUpperCase() === 'FIAT' && status === 'CANCELLED') {
        return (
          <Space>
            <PermissionCheck>
              <ButtonCommon
                className='filter-btn'
                reject='approve'
                onClick={() => showModal(record, 'reapprove')}
              >
                <span style={{ color: 'black' }}>Re-Approve</span>
              </ButtonCommon>
            </PermissionCheck>
            <EyeOutlined
              style={{ color: iconColor, fontSize: '20px' }}
              onClick={() => {
                handleViewTransactionsDetails(record);
                // dispatch(
                //   setTransactionDetail({ ...record, type: record?.type.toUpperCase() })
                // );
              }}
            />
          </Space>
        );
      }

      return (
        // <ButtonCommon
        //   type="primary"
        //   onClick={() => handleViewTransactionsDetails(record)}
        // >
        //   <EyeOutlined style={{ color: "brown" }} />
        // </ButtonCommon>
        <EyeOutlined
          style={{ color: iconColor, fontSize: iconSize }}
          onClick={() => handleViewTransactionsDetails(record)}
        />
      );
    }
    return (
      <Space>
        <PermissionCheck>
          <ButtonCommon
            type='primary'
            text='Approve'
            onClick={() => showModal(record)}
          />
        </PermissionCheck>
        <Button
          type='primary'
          onClick={() => handleViewTransactionsDetails(record)}
        >
          More Details
        </Button>
      </Space>
    );
  };

  const formatTxnType = (type) => {
    switch (type) {
      case 'FIAT_WITHDRAW':
        return 'WITHDRAW';
      case 'FIAT_DEPOSIT':
        return 'DEPOSIT';
      case 'CRYPTO_WITHDRAW':
        return 'WITHDRAW';
      case 'CRYPTO_DEPOSIT':
        return 'DEPOSIT';
      default:
        return '-';
    }
  };

  const columns = [
    {
      title: 'Coin Name',
      dataIndex: 'coinName',
      key: 'coinName',
      render: (_, record) => checkEmptyString(record.coinName.toUpperCase()),
    },
    {
      title: 'Txn Type',
      dataIndex: 'txnType',
      key: 'txnType',
      render: (_, record) => checkEmptyString(formatTxnType(record.txnType)),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => checkEmptyString(record.type),
    },

    {
      title: 'Amount ',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => checkEmptyString(record.amount),
    },
    {
      title: 'Status ',
      dataIndex: 'txnStatus',
      key: 'txnStatus',
      render: (_, record) => checkEmptyString(record.txnStatus),
    },
    {
      title: 'From',
      dataIndex: 'fromAddress',
      key: 'fromAddress',
      render: (_, record) => {
        return (
          <>
            {record?.fromAddress ? (
              <>
                <span style={{ cursor: 'pointer' }}>
                  {handleHideId(record?.fromAddress)}
                </span>

                <CopyToClipboard
                  fromAddress={record?.fromAddress}
                  position='top'
                />
              </>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'To',
      dataIndex: 'toAddress',
      key: 'toAddress',
      render: (_, record) => {
        return (
          <>
            {record?.toAddress ? (
              <>
                <span style={{ cursor: 'pointer' }}>
                  {handleHideId(record?.toAddress)}
                </span>

                <CopyToClipboard toAddress={record?.toAddress} position='top' />
              </>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => formatDateTime(record.updatedAt),
    },

    {
      title: 'Action',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return <>{showActionsPerType(record)}</>;
      },
    },
  ];

  const updateComplianceFiatStatus = async () => {
    try {
      dispatch(startLoading());
      let data = {
        status: clickedStatus === 'reject' ? 'REJECTED' : 'APPROVED',
        transactionId: fiatTxnRecord?.txnId,
      };
      await dispatch(BankingActions.updateComplianceFiatStatus(data)).unwrap();

      const kytData = {
        offset: 0,
        size: pageSize,
        txnStatus: filterData.txnStatus,
        coinName: '',
        type: 'FIAT',
      };
      setShowCancelFiat(false);
      getKytTxn(kytData);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  const ReApprovedFiatComplianceStatus = async () => {
    console.log('fiatTxnRecord::', fiatTxnRecord);
    try {
      dispatch(startLoading());
      let data = {
        transactionId: fiatTxnRecord?.txnId,
      };
      await dispatch(
        BankingActions.updateComplianceReApproveFiatStatus(data)
      ).unwrap();
      const kytData = {
        offset: 0,
        size: pageSize,
        txnStatus: filterData.txnStatus,
        coinName: '',
        type: 'FIAT',
      };
      setShowCancelFiat(false);
      getKytTxn(kytData);
      // dispatch(setTransactionDetail({ ...transactionDetail, status: '' }));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finishLoading());
    }
  };

  return (
    <>
      <div className='table-design'>
        <div className='title-filter'>
          <ComplianceFilterTittle title='Fiat Transactions' />
          {/* <div className="title-filter flexEnd">
          <div className="title-filter_rightSec"> */}
          <TransactionScreeningFilters
            setFilterData={setFilterData}
            pageSize={pageSize}
            getKytTxn={getKytTxn}
            setPage={setPage}
            type={'FIAT'}
          />
          {/* </div>
        </div> */}
        </div>
        <div className='custom-table'>
          <Table
            dataSource={kytDetails.list}
            columns={columns}
            pagination={false}
            //    // scroll={{ x: "auto", y: "calc(100vh - 400px)" }}
          />
        </div>
      </div>
      <div className='pagination_block' style={{ marginTop: '20px' }}>
        <span className='records'>
          Total Records: {kytDetails.totalCount > 0 ? kytDetails.totalCount : 0}
        </span>
        {kytDetails.totalCount > 10 && (
          <Pagination
            defaultCurrent={page}
            total={kytDetails.totalCount}
            pageSize={pageSize}
            onChange={handlePageChange}
            current={page}
          />
        )}
      </div>

      <Modal
        className='logout custom-modal'
        width={545}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        maskClosable={false}
      >
        <div className='modal-inner'>
          <h3>Are you sure want to Approve?</h3>
          <p></p>
          <div className='logout_btn'>
            <ButtonCommon
              text='No'
              width={176}
              height={46}
              transBtn='transBtn'
              custmcls='cursorpointer'
              onClick={handleCancel}
            />
            <ButtonCommon
              type='primary'
              text='Yes'
              width={176}
              onClick={handleOk}
              customBlueBtn='customBlueBtn'
              height={46}
            />
          </div>
        </div>
      </Modal>

      <CustomModal
        title={`Are you sure, you want to ${clickedStatus} this transaction?`}
        open={showCancelFiat}
        onOk={
          clickedStatus === 'reapprove'
            ? ReApprovedFiatComplianceStatus
            : updateComplianceFiatStatus
        }
        onCancel={() => setShowCancelFiat(false)}
      ></CustomModal>
    </>
  );
};

export default FiatScreening;
