import React, { useState, useEffect } from 'react';
import { Button, Select, DatePicker, Space, Form } from 'antd';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form'; // Import React Hook Form
import InputCustom from '../../Components/InputCustom/InputCustom.jsx';
import { CONSTANTS } from '../../constants/index.js';
import { Utils } from '../../utils/index.js';
import ButtonCommon from '../../Components/Buttoncommon/ButtonCommon.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import toast from '../../services/toast.service.js';

const { USER_TYPE, VERIFICATION_STATUS, CLIENT_STATUS } = CONSTANTS;
const { formatDate } = Utils.common;

const Filttercustom = ({
  pageSize,
  filterData,
  placeholder,
  setPage,
  data,
  isUserDirectory,
  total,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm(); // Get form control and handleSubmit

  const [isFilterVisible, setIsFilterVisible] = useState(true);

  const obj = { ...location.state?.filter };

  const [filter, setFilter] = useState({
    status: obj.status,
    verification: obj.verification,
    nationality: obj.nationality,
    userType: obj.userType,
    startDate: obj.startDate,
    endDate: obj.endDate,
    dateRange: obj.dateRange,
  });

  const setRangePickerValue = () => {
    return {
      startDate: dayjs(filter.startDate),
      endDate: dayjs(filter.endDate),
    };
  };

  const checkEmptyFilters = (data) => {
    let filterMessage = '';
    let isFiltersValid = true;
    isFiltersValid = Object.values(data).some((value) => !!value);
    if (!isFiltersValid) {
      if ((data && data.length === 0) || total == 0) {
        isFiltersValid = true;
      } else {
        filterMessage = 'Please select filters first';
        toast.error(filterMessage);
      }
    }

    return isFiltersValid;
  };

  const validateFilters = (data) => {
    const { VALIDATION_ERRORS } = CONSTANTS;
    let isFiltersValid = checkEmptyFilters(data);

    if (data.startDate && data.endDate) {
      if (
        new Date(data.startDate).getDate() > new Date(data.endDate).getDate() &&
        new Date(data.startDate).getMonth() > new Date(data.endDate).getMonth()
      ) {
        isFiltersValid = false;
        toast.error(VALIDATION_ERRORS.START_DATE_GREATER_THAN_END_DATE);
      }
    }

    return isFiltersValid;
  };

  const onSubmit = (data) => {
    let isFiltersValid = validateFilters(data);
    if (!isFiltersValid) return;

    const values = convertFilterValues(data);
    if (Object.keys(values).length === 0) return;
    setPage(1);
    filterData({
      limit: pageSize,
      offset: 0,
      // filter: { ...data, ...values },
      filter: values,
    });
  };

  const convertFilterValues = (data) => {
    let values = { ...data };

    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        if (
          typeof values[key] === 'undefined' ||
          values[key] == '' ||
          values[key] == null
        )
          delete values[key];
      }
    }

    if (values.startDate && !values.endDate)
      values.endDate = formatDate(new Date().toDateString());
    if (values.endDate && !values.startDate)
      return toast.error('Please select from date');

    if (values.startDate) values.startDate = formatDate(values.startDate);
    if (values.endDate) {
      values.endDate = formatDate(values.endDate);
      setValue('endDate', dayjs(values.endDate));
    }

    return values;
  };

  useEffect(() => {
    console.log('filters:- ', filter);
    setValue('userType', filter.userType);
    setValue('nationality', filter.nationality);
    setValue('verification', filter.verification);
    setValue('status', filter.status);
    if (filter.startDate) {
      setValue('startDate', setRangePickerValue().startDate);
    }
    if (filter.endDate) {
      setValue('endDate', setRangePickerValue().endDate);
    }
  }, [filter]);

  const clearFilters = () => {
    let isFiltersValid = checkEmptyFilters(getValues());
    if (isFiltersValid) {
      reset();
      setFilter({
        nationality: '',
      });
      setTimeout(() => {
        filterData({
          limit: pageSize,
          offset: 0,
          filter: {},
        });
      }, 1000);
      setPage(1);
    }
  };

  const setSelectLib = (lib) =>
    Object.keys(lib).map((key) => ({ value: key, label: lib[key] }));

  return (
    <>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSubmit(onSubmit)} // Use handleSubmit from React Hook Form
        layout='vertical'
      >
        <>
          <Space size={[10, 10]} wrap>
            <Controller
              name='startDate'
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  placeholder='From date'
                  style={{ height: '48px' }}
                />
              )}
            />

            <Controller
              name='endDate'
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  placeholder='To date'
                  style={{ height: '48px' }}
                />
              )}
            />

            <Controller
              name='nationality'
              control={control}
              render={({ field }) => (
                <>
                  <InputCustom
                    type='text'
                    settingInput='settingInput'
                    custommClass1='custommClass1'
                    placeholder={'Nationality'}
                    borderRadius={10}
                    height={48}
                    // {...register("nationality")}
                    {...field}
                  />
                  {/* <CustomInput
                    type="text"
                    placeholder="Nationality"
                    {...field}
                  /> */}
                </>
              )}
            />

            <Controller
              name='userType'
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    // value={field.value || 'User Type'}
                    placeholder='User Type'
                    style={{ width: '140px' }}
                    options={setSelectLib(USER_TYPE)}
                  />
                </>
              )}
            />

            {isUserDirectory && (
              <>
                <Controller
                  name='verification'
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder='Verification'
                      style={{ width: '140px' }}
                      options={setSelectLib(VERIFICATION_STATUS)}
                    />
                  )}
                />

                <Controller
                  name='status'
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder='Status'
                      style={{ width: '140px' }}
                      options={setSelectLib(CLIENT_STATUS)}
                    />
                  )}
                />
              </>
            )}

            <div className='d-flex'>
              <ButtonCommon
                text='Submit'
                type='primary'
                transBtn='transBtn'
                htmlType={'submit'}
              />
              <ButtonCommon
                className='filter-btn'
                reject='reject'
                // onClick={toggleFilterVisibility}
                // type={isFilterVisible ? "primary" : "default"}
                onClick={clearFilters}
                htmlType='button'
              >
                {isFilterVisible ? <CloseOutlined /> : <FilterOutlined />}
              </ButtonCommon>
            </div>
          </Space>
        </>
      </Form>
    </>
  );
};

export default Filttercustom;
